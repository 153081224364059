import L from "leaflet";

import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import iconUrl from "../assets/icon/marker-green.svg";


export default () => {
  if (typeof window !== 'undefined') {
    return L.icon({
      iconUrl: iconUrl,
      iconRetinaUrl: iconUrl,
      iconAnchor: [12, 12],
      popupAnchor: null,
      shadowUrl: shadowUrl,
      shadowSize: [11, 11],
      shadowAnchor: [11, 11],
      iconSize: [24, 24],
    });
  }
  return null
}
