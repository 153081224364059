import L from "leaflet";

import iconRetinaUrl from '../assets/icon/Parada.svg'
import iconUrl from '../assets/icon/Parada.svg'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'


export default () => {
  if (typeof window !== 'undefined') {
    return L.icon({
      iconUrl: iconUrl,
      iconRetinaUrl: iconRetinaUrl,
      shadowUrl: shadowUrl,
      iconSize: [15, 15],
      iconAnchor: [8, 8],
      popupAnchor: [1, -10],
      tooltipAnchor: [5, -10],
      shadowSize: [18, 18],
    });
  }
  return null
}
